@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles reset to ensure consistency */
html, body {
  font-family: 'Poppins', sans-serif; /* Applying Poppins globally */
  color: #f9fafb; /* Default text color */
  background-color: #000; /* Dark background for contrast */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.5;
  background: linear-gradient(180deg, #000, #0d0d0d);
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

input, button {
  outline: none;
  border: none;
}

/* Ensures that the form field aligns and behaves properly */
input {
  background-color: transparent;
}

input[type="text"] {
  border: 1px solid #d1d5db;
  border-right: none;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0; /* Ensure no right padding on the input */
}

input[type="text"].tenant-input {
  border: 1px solid #d1d5db;
  border-right: none;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: none;
  -webkit-text-transform: none;
  -moz-text-transform: none;
}

/* Additional styles to prevent auto-capitalization and spell checking */
.tenant-input {
  text-transform: none !important;
  -webkit-text-transform: none !important;
}

/* Add this to your globals.css or as inline styles */
button {
  transition: transform 0.2s ease, background-color 0.2s ease;
}

button:hover {
  background-color: #fe602f; /* A more vibrant orange */
  transform: scale(1.05);    /* Slightly enlarges the button on hover */
}

input:focus {
  transition: border-color 0.3s ease;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

p {
  font-weight: 400;
  line-height: 1.6;
}

.domain-suffix {
  border: 1px solid #d1d5db;
  border-left: none;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  padding-left: 0; /* Remove left padding */
  background-color: white; /* Ensure background color is set */
}